<template functional>
    <span>
        <LTooltip
            v-if="props.webmasterReward != null"
            :label="parent.$t('admin.offers.table.tooltips.generalRate')"
            position="is-top"
            type="is-dark">
            <span>
                {{ $options.methods.formatCurrency(props.webmasterReward.value, props.webmasterReward.currency) }}
            </span>
        </LTooltip>
        <template v-if="props.min != null && props.max != null">
            (<LTooltip
                :label="parent.$t('admin.offers.table.tooltips.minRate')"
                position="is-top"
                type="is-dark">
                <span>
                    {{ $options.methods.formatCurrency(props.min.value, props.min.currency) }}
                </span>
            </LTooltip>
            -
            <LTooltip
                :label="parent.$t('admin.offers.table.tooltips.maxRate')"
                position="is-top"
                type="is-dark">
                <span>
                    {{ $options.methods.formatCurrency(props.max.value, props.max.currency) }}
                </span>
            </LTooltip>)
        </template>
        <template v-else-if="props.min != null || props.max != null">
            (<LTooltip
                :label="parent.$t('admin.offers.table.tooltips.webmasterRate')"
                position="is-top"
                type="is-dark">
                <span>
                    {{ $options.methods.formatCurrency(
                        props.min != null ? props.min.value : props.max.value,
                        props.min != null ? props.min.currency : props.max.currency)
                    }}
                </span>
            </LTooltip>)
        </template>
    </span>
</template>

<script>
  import { formatCurrency } from "@core/filters";
  
  export default {
    name: "WebmasterRateOutput",

    props: {
      webmasterReward: {
        type: Object,
        default: null
      },

      min: {
        type: Object,
        default: null
      },

      max: {
        type: Object,
        default: null
      }
    },

    methods: {
      formatCurrency
    }
  };
</script>

<style scoped>

</style>
